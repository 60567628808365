var prodcat = prodcat || {};
var site = site || {};

prodcat.data = prodcat.data || {};

(function ($, prodcat) {
  Drupal.behaviors.sizePickerV1 = {
    isMobile: false,

    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;
      $sizePickers = $('.js-size-select--dropdown', context);

      $sizePickers.each(function () {
        var $this = $(this);
        self.getDom($this);
        self.setEvents($this);
        self.setup($this);
      });
    },
    setup: function ($that) {
      var self = this;
      var $selectedOption = $that.find('option:selected');
      var bundleSizePickerClass = $that.nodes.$bundle.length > 0 ? ' pdp-bundle-item--size-picker-options' : '';
      $that.data('triggerSkuChange', true);
      $that.select2({
        adaptDropdownCssClass: function (c) {
          if (c.indexOf('size-select--dropdown') === 0) {
            return 'size-picker-menu--v1 js-size-picker-menu--v1' + bundleSizePickerClass;
          }
        },
        dropdownParent: $that.nodes.$container,
        minimumResultsForSearch: -1
      });
      self.highlightChosenPrice($that, $selectedOption);
    },
    highlightChosenPrice: function ($that, $selectedOption) {
      var self = this;
      $chosen = $that.nodes.$container.find('.select2-chosen');
      self.highlightOptionPrices($chosen, $selectedOption);
    },
    highlightOptionPrices: function ($chosen, $option) {
      var self = this;
      var innerText = $chosen.html();
      if (!innerText) {
        return;
      }
      innerText = self.highlightTextPrices(innerText, $option);
      $chosen.html(innerText);
    },
    highlightTextPrices: function (text, $option) {
      if (!text) {
        return;
      }
      var cssClass = '';
      if (!!$option.data('discountPrice')) {
        text = text.replace(
          $option.data('discountPrice'),
          '<span class="product-price--discounted js-product-price--discounted">' +
            $option.data('discountPrice') +
            '</span>'
        );
      }
      if (!!$option.data('salePrice')) {
        text = text.replace(
          $option.data('salePrice'),
          '<span class="product-price--sale js-product-price--sale">' +
            $option.data('salePrice') +
            '</span>'
        );
      }
      if (!!$option.data('displayTax') && !!$option.data('taxedPrice')) {
        cssClass = $option.data('discountPrice') ? ' product-price--sale' : '';
        text = text.replace(
          $option.data('taxedPrice'),
          '<span class="js-product-taxed-price-v1' +
            cssClass +
            '">' +
            $option.data('taxedPrice') +
            '</span>'
        );
        text = text.replace(
          $option.data('withTax'),
          '<span class="' + cssClass + '">' + $option.data('withTax') + '</span>'
        );
        text = '<span class="product-price--standard"> ' + text + '</span>';
      }
      return text;
    },
    highlightOptionsPrices: function ($that) {
      var self = this;
      var $select2Options = $that.data('select2').dropdown.find('.select2-result-label');
      $that.find('option').each(function (index) {
        var $currentOption = $(this);
        self.highlightOptionPrices($select2Options.eq(index), $currentOption);
      });
    },
    getDom: function ($that) {
      $that.nodes = {};
      $that.nodes.$product = $that.closest('.js-product-ui');
      $that.nodes.$bundle = $that.closest('.js-pdp-bundle-item');
      $that.nodes.$container = $that.closest('.js-size-select');
      $that.nodes.$productSize = $('.js-product-full-size', $that.nodes.$product);
      $that.nodes.$productPricePerUnite = $('.js-product-full__ppu', $that.nodes.$product);
    },
    setEvents: function ($that) {
      var self = this;
      $that
        .once()
        .on('change', function () {
          var selectedSkuId = $(this).val();
          var skuData = prodcat.data.getSku(selectedSkuId);
          var $this = $(this);
          self.highlightChosenPrice($that, $this.find('option:selected'));
          if ($that.data('triggerSkuChange')) {
            $that.nodes.$product.trigger('product.skuSelect', [selectedSkuId]);
          }
          if ($that.nodes.$bundle.length < 1) {
            // Only trigger sku change on main product, if dropdown is not inside a bundle.
            $that.data('triggerSkuChange', true);
          } else {
            $that.nodes.$bundle.trigger('productBundle.skuSelect', selectedSkuId);
          }
          // Change product size
          if (!!skuData?.PRODUCT_SIZE && $that.nodes.$bundle.length < 1) {
            $that.nodes.$productSize.length > 0 && $that.nodes.$productSize.text(skuData.PRODUCT_SIZE);
            $that.nodes.$productPricePerUnite.length > 0 && $that.nodes.$productPricePerUnite.text(skuData.formattedUnitPrice);
          }
        })
        .on('select2-opening', function () {
          $('option:selected', $(this)).addClass('select2-result-active');
        })
        .on('select2-open', function () {
          self.highlightOptionsPrices($that);
        })
        .on('select2-selecting', function () {
          $('option', $(this)).removeClass('select2-result-active');
        })
        .on('select2-select', function () {
          $('option:selected', $(this)).addClass('select2-result-active');
        });
      $(document).on('product.skuSelect', '.js-product-ui', function (e, skuBaseId) {
        var $product = $(this);
        if ($product.find($that).length > 0) {
          $product.find($that).data('triggerSkuChange', false).val(skuBaseId).change();
        }
      });
    },
    attached: false
  };
})(jQuery, window.prodcat || {});
